import { render, staticRenderFns } from "./TermsOfUseModal.vue?vue&type=template&id=6a68f9be&scoped=true"
import script from "./TermsOfUseModal.vue?vue&type=script&lang=js"
export * from "./TermsOfUseModal.vue?vue&type=script&lang=js"
import style0 from "./TermsOfUseModal.vue?vue&type=style&index=0&id=6a68f9be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a68f9be",
  null
  
)

export default component.exports