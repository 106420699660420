<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
          <card id="form-register" class="mb-0">
            <template slot="header">
              <h4 class="card-title">{{ $t('register.createAccount') }}</h4>
              <p>{{ $t('register.registerNewAccount') }}</p>
            </template>
            <ValidationProvider :name="$t('input.companyName')" rules="required" v-slot="{ passed, failed, errors }">
              <base-input
                required
                v-model="company_name"
                :placeholder="$t('input.companyName')"
                addon-left-icon="tim-icons icon-spaceship"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              ></base-input>
            </ValidationProvider>
            <ValidationProvider :name="$t('input.email')" rules="required|email" v-slot="{ passed, failed, errors }">
              <base-input
                required
                v-model="email"
                :placeholder="$t('input.email')"
                addon-left-icon="tim-icons icon-email-85"
                type="email"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              ></base-input>
            </ValidationProvider>
            <ValidationProvider :name="$t('input.password')" rules="required" v-slot="{ passed, failed, errors }">
              <base-input
                required
                v-model="password"
                :placeholder="$t('input.password')"
                addon-left-icon="tim-icons icon-lock-circle"
                type="password"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              ></base-input>
            </ValidationProvider>
            <p slot="footer">{{ $t('register.acceptTerms') }}</p>
            <terms-of-use-modal :isVisible="isModalVisible" @close="isModalVisible = false"
                                @accepted="handleAccepted"></terms-of-use-modal>
            <base-button @click="openModal" v-if="!isUserTermsAccepted" slot="footer" type="primary" round block
                         size="lg">
               {{ $t('register.readTermsAndConditions') }}
            </base-button>
            <div v-if="isUserTermsAccepted" id="userTermsAccepted">
              <base-button native-type="submit" slot="footer" type="primary" round block size="lg">
                 {{ $t('register.btnRegister') }}
              </base-button>
              <p>{{ $t('register.redirectionLogin') }}
                <router-link to="/login">Log in</router-link>
              </p>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {extend} from 'vee-validate';
import TermsOfUseModal from "@/pages/Pages/TermsOfUseModal.vue";
import {required, email} from 'vee-validate/dist/rules';
import swal from 'sweetalert2';
import {refreshContext} from '@/utils';
import {BaseAlert} from 'src/components';

extend('email', email);
extend('required', required);

export default {
  components: {TermsOfUseModal, BaseAlert},
  data() {
    return {
      email: '',
      password: '',
      company_name: '',
      isModalVisible: false,
      isUserTermsAccepted: false,
      domains: ["online.de", "alice.it", "virgilio.it", "tin.it", "tim.it", "aol.com", "aol.fr", "me.com", "mac.com", "icloud.com", "arcor.de", "bluewin.ch", "blueyonder.co.uk", "bbox.fr", "btinternet.com", "comcast.net", "email.it", "facebook.com", "free.fr", "aliceadsl.fr", "infonie.fr", "libertysurf.fr", "online.fr", "freesbee.fr", "alicepro.fr", "worldonline.fr", "freenet.de", "gmx.de", "gmx.net", "gmx.at", "caramail.com", "gmx.fr", "gmail.com", "googlemail.com", "home.nl", "laposte.net", "libero.it", "blu.it", "giallo.it", "mail.ru", "bk.ru", "hotmail.com", "live.com", "msn.com", "outlook.com", "windowslive", "dbmail.com", "hotmail.fr", "live.fr", "msn.fr", "hotmail.be", "msn.be", "live.be", "hotmail.de", "hotmail.it", "hotmail.co.uk", "hotmail.es", "live.co.uk", "live.it", "live.nl", "live.se", "live.de", "hotmail.nl", "outlook.fr", "hotmail.se", "live.dk", "live.com.pt", "telefonica.es", "movistar.es", "numericable.fr", "noos.fr", "o2.pl", "orange.fr", "wanadoo.fr", "skynet.be", "rambler.ru", "lenta.ru", "autorambler.ru", "myrambler.ru", "ro.ru", "r0.ru", "sfr.fr", "neuf.fr", "9online.fr", "9business.fr", "cegetel.net", "club-internet.fr", "cario.fr", "guideo.fr", "mageos.com", "fnac.net", "waika9.com", "sky.com", "telenet.be", "tiscali.it", "tiscali.co.uk", "t-online.de", "verizon.net", "ono.com", "voila.fr", "web.de", "wp.pl", "yahoo.com", "ymail", "rocketmail", "yahoo.fr", "yahoo.co.uk", "yahoo.es", "yahoo.de", "yahoo.it", "ymail.com", "yahoo.com.tw", "rocketmail.com", "yahoo.se", "yandex.ru", "mail.com", "talktalk.net"]
    };
  },
  created() {
    const storedVersion = localStorage.getItem('owlyscanVersion');
    if (storedVersion !== this.$owlyscanVersion) {
      localStorage.setItem('owlyscanVersion', this.$owlyscanVersion);
      window.location.reload();
    }
  },
  mounted() {
    this.getDomains();
  },
  methods: {
    async submit() {
      if (this.checkEmail()) {
        this.$recaptcha('register').then((token) => {
          this.submitForm(token);
        }).catch(error => {
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Invalid CAPTCHA',
            customClass: {
              confirmButton: 'btn btn-danger btn-fill',
            },
            buttonsStyling: false,
          });
        });
      }
    },
    async submitForm(token) {
      try {
        const accessToken = localStorage.getItem('userToken');
        const response = await fetch(this.$apiEndpoint + `/api/register/`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "account": this.company_name,
            "email": this.email,
            "password": this.password,
            "captcha_token": token
          })
        });

        const responseBody = await response.json(); // This will work for both success and error responses

        if (!response.ok) {
          // If the response is not okay, throw an error with the response body
          throw responseBody;
        }

        swal.fire({
          title: this.$t("register.welcome"),
          text: this.$t("register.checkMailbox"),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
          },
          icon: 'success',
        });
        this.$router.push("/login");

      } catch (error) {
        // const errorMessage = this.$t("errors.backend."+error.detail) || 'Unknown error occurred';
        let errorMessage = 'Unknown error occurred';
        if (this.$t("errors.backend."+error.detail) != "errors.backend."+error.detail) {
          errorMessage = this.$t("errors.backend."+error.detail);
        }
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: errorMessage,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        });
      }
    },
    openModal() {
      this.isModalVisible = true; // Open the modal
    },
    handleAccepted() {
      this.isUserTermsAccepted = true;
      this.isModalVisible = false;
    },
    isAnyFreeDomain(domains, email) {
      return domains.some((domain) => {
        return email.endsWith("@"+domain);
      });
    },
    getDomains() {
      const fetchOptions = {
        method: "GET",
      };
      const apiUrl = `https://raw.githubusercontent.com/disposable/disposable-email-domains/master/domains.txt`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          data.split("\n").forEach(d => {
            this.domains.push(d)
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: this.$t('errors.error') + ` - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    checkEmail() {
      if (this.isAnyFreeDomain(this.domains, this.email)) {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t("register.notCompanyEmail"),
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        });
        return false;
      }
      return true;
    }
  },
};
</script>

<style>
#form-register {
  background-color: white;
  color: black;
  border-radius: 20px;
}

#form-register i {
  color: black;
}

#form-register h4 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}

#form-register input {
  height: 4em;
}

#form-register p {
  color: black;
}
</style>
