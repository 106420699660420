<template>
  <div v-if="isVisible" class="modal">
    <div class="modal-content">
      <button class="close-btn" @click="closeModal">&times;</button>
      <h2  style="color: black">{{ $t('register.modalTermsAndConditions.title') }}</h2>
      <div class="terms-text">
        <p v-html="terms"></p>
        <!-- {{ terms }} -->
      </div>
      <div class="form-group">
        <base-checkbox v-model="accepted"><p style="color: black">{{ $t('register.modalTermsAndConditions.agree') }}</p></base-checkbox>
      </div>
      <base-button size="lg" type="primary" :disabled="!accepted" @click="acceptTerms">{{ $t('register.modalTermsAndConditions.btnAccept') }}</base-button>
    </div>
  </div>
</template>

<script>

import {terms_en, terms_fr} from "@/pages/Pages/UserProfile/TermsOfUseModalTexts";

export default {
  name: "TermsOfUseModal",
  props: {
    isVisible: Boolean,
  },
  data() {
    return {
      accepted: false,
    };
  },
  computed: {
    terms() {
      switch (this.$i18n.locale) {
        case "fr":
          return terms_fr
        default:
          return terms_en
      }
    }
  },
  methods: {
    terms_en() {
      return terms_en
    },
    acceptTerms() {
      if (this.accepted) {
        this.$emit('close');
        this.$emit('accepted');
      }
    },
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  height: 80%;
  box-sizing: border-box;
  overflow-y: auto; /* Allows scrolling within the modal */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 30px;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
}

.terms-text {
  margin: 20px 0;
}
</style>
