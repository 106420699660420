<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto text-center">
          <h1 class="title"> {{ $t('buy.transfer.title') }}</h1>
          <h2>
            {{ $t('buy.transfer.subtitle') }}
          </h2>
        </div>
      </div>
    </div>

    <div class="container-fluid justify-content-center">
      <div class="row">
        <div class="col-12 d-flex flex-row flex-wrap justify-content-around">
          <div v-for="(pricingCard, index) in pricingCards" :key="index">
            <pricing-payment-card :title="pricingCard.title" :features="pricingCard.features" :price="pricingCard.price"
                                  :price_detail="pricingCard.price_detail"
                                  :image-src="pricingCard.imageSrc"
                                  :footers="pricingCard.footers"
                                  :is-featured="pricingCard.isFeatured" :price_id="pricingCard.price_id"
                                  :is-subscription="pricingCard.isSubscription"
                                  class="p1 pricing-card"></pricing-payment-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {BaseProgress} from 'src/components';
import PricingPaymentCard from "@/pages/Pages/PricingPaymentCard.vue";

export default {
  data() {
    return {
      "pricingCards": [
        {
          "title": "100",
          "imageSrc": "card-success.png",
          "features": {
            "en": ["100 Queries / year", "3-year subscription"],
            "fr": ["100 Recherches / an", "Abonnement 3 ans"]
          },
          "price": 4500,
          "price_detail": {
            "en": "Excl. tax / year",
            "fr": "HT/an"
          },
          "footers": {
            "en": ["45 € / Query", "By bank transfer", "Usable within 12 months", "Excess carried over to the following year", "Recharge possible by 10, 500 or 1000"],
            "fr": ["45 € / Recherche", "Par virement", "Utilisable dans les 12 mois", "Excédent reportable sur l'année suivante", "Recharge possible par 10, 500 ou 1000"]
          },
          "isFeatured": false,
          "price_id": "sub-100",
          "isSubscription": true
        },
        {
          "title": "500",
          "imageSrc": "card-warning.png",
          "features": {
            "en": ["500 Queries", "3-year subscription"],
            "fr": ["500 Recherches", "Abonnement 3 ans"]
          },
          "price": 8500,
          "price_detail": {
            "en": "Excl. tax / year",
            "fr": "HT/an"
          },
          "footers": {
            "en": ["17 € / Query", "By bank transfer", "Usable within 12 months", "Excess carried over to the following year", "Recharge possible by 10, 500 or 1000"],
            "fr": ["17 € / Recherche", "Par virement", "Utilisable dans les 12 mois", "Excédent reportable sur l'année suivante", "Recharge possible par 10, 500 ou 1000"]
          },
          "isFeatured": false,
          "price_id": "sub-500",
          "isSubscription": true
        },
        {
          "title": "1000",
          "imageSrc": "card-danger.png",
          "features": {
            "en": ["1000 Queries", "3-year subscription"],
            "fr": ["1000 Recherches", "Abonnement 3 ans"]
          },
          "price": 15000,
          "price_detail": {
            "en": "Excl. tax / year",
            "fr": "HT/an"
          },
          "footers": {
            "en": ["15 € / Query", "By bank transfer", "Usable within 12 months", "Excess carried over to the following year", "Recharge possible by 10, 500 or 1000"],
            "fr": ["15 € / Recherche", "Par virement", "Utilisable dans les 12 mois", "Excédent reportable sur l'année suivante", "Recharge possible par 10, 500 ou 1000"]
          },
          "isFeatured": false,
          "price_id": "sub-1000",
          "isSubscription": true
        },
        // {
        //   "title": "Total",
        //   "imageSrc": "card-primary.png",
        //   "features": {
        //     "en": ["3-year subscription"],
        //     "fr": ["Abonnement 3 ans"]
        //   },
        //   "price": 50000,
        //   "price_detail": {
        //     "en": "Excl. tax / year",
        //     "fr": "HT/an"
        //   },
        //   "footers": {
        //     "en": ["Unlimited searches", "By bank transfer", "100 users"],
        //     "fr": ["Recherches illimitées", "Par virement", "100 utilisateurs"]
        //   },
        //   "isFeatured": false,
        //   "price_id": "sub-unlimited",
        //   "isSubscription": true
        // }
      ],
    }
  },
  components: {
    // BaseProgress,
    PricingPaymentCard,
  }
};
</script>
<style>
.pricing-card {
  /*
  //flex-grow: 1;
  //flex-shrink: 0;
  //flex-basis: 0;
  */
  flex: 0 0 20%;
}
</style>
