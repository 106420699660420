<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
          <card id="form-register" class="mb-0">
            <template slot="header">
              <h4 class="card-title">{{ $t('login.login') }}</h4>
            </template>
            <div>
              <ValidationProvider :name="$t('input.email')" rules="required|email" v-slot="{ passed, failed, errors }">
                <base-input
                  required
                  v-model="email"
                  :placeholder="$t('input.email')"
                  addon-left-icon="tim-icons icon-email-85"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  type="email"
                ></base-input>
              </ValidationProvider>
              <ValidationProvider :name="$t('input.password')" rules="required|min:5" v-slot="{ passed, failed, errors }">
                <base-input
                  required
                  v-model="password"
                  :placeholder="$t('input.password')"
                  addon-left-icon="tim-icons icon-lock-circle"
                  type="password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                ></base-input>
              </ValidationProvider>
            </div>
            <base-alert type="danger" v-if="errorLoginMessage">
              <span><b>{{ $t('errors.error') }} - </b>{{ errorLoginMessage }}</span>
            </base-alert>
            <base-alert type="success" v-if="isAccountActivated">
              <span>{{ $t('login.accountActivated') }}</span>
            </base-alert>
            <base-alert type="warning" v-if="isDoLogin">
              <span>{{ $t('errors.doLogin') }}</span>
            </base-alert>
            <div slot="footer">
              <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>
                {{ $t('login.btnLogin') }}
              </base-button>
              <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/register">
                    {{ $t('login.redirectionRegister') }}
                  </router-link>
                </h6>
              </div>
              <div class="pull-right">
                <h6><a href="#/reset-password" class="link footer-link">{{ $t('login.redirectionHelp') }}</a></h6>
              </div>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {extend} from 'vee-validate';
import {required, email, min} from 'vee-validate/dist/rules';
import {BaseAlert} from 'src/components';

extend('email', email);
extend('min', min);
extend('required', required);

export default {
  components: {
    BaseAlert,
  },
  data() {
    return {
      email: '',
      password: '',
      subscribe: true,
      errorLoginMessage: '',
      isAccountActivated: false,
      isDoLogin: false
    };
  },
  created() {
    const storedVersion = localStorage.getItem('owlyscanVersion');
    if (storedVersion !== this.$owlyscanVersion) {
      localStorage.setItem('owlyscanVersion', this.$owlyscanVersion);
      window.location.reload();
    }
  },
  mounted() {
    // this.handleEmailLink();
    this.checkUrlParameter();
  },
  methods: {
    checkUrlParameter() {
      const params = new URLSearchParams(window.location.search);
      const linkStatus = params.get('link_status');
      if (linkStatus) {
        switch (linkStatus) {
          case "ACCOUNT_ACTIVATED":
            this.isAccountActivated = true;
            break;
          case "UNKNOWN_LINK":
            this.errorLoginMessage = "Unknown link"
            break;
          case "LINK_TOO_OLD":
            this.errorLoginMessage = "Link is too old. Please register again";
            break;
        }
      }
      const lang = params.get('lang');
      if (lang) {
        this.$i18n.locale = lang;
        this.currentLang = lang;
        this.loadLocale(lang);
        localeChanged();
      }
      const code = params.get('code');
      if (code === '401') {
        this.isDoLogin = true;
      }
      this.removeUrlParameter();
    },
    removeUrlParameter() {
      const url = new URL(window.location);
      url.searchParams.delete('code');
      url.searchParams.delete('link_status');
      url.searchParams.delete('lang');
      window.history.pushState({}, '', url);
    },
    submit() {
      this.isDoLogin = false;
      this.login();
    },
    async login() {
      try {
        const formData = new FormData();
        formData.append('username', this.email);
        formData.append('password', this.password);

        const response = await fetch(this.$apiEndpoint + '/api/token', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          const accessToken = data.access_token;
          localStorage.setItem('userToken', accessToken);

          const contextResponse = await fetch(this.$apiEndpoint + '/api/context', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (contextResponse.ok) {
            const contextData = await contextResponse.json();
            const defaultOrg = contextData.orgs.find((org) => org.is_default) || contextData.orgs[0];
            contextData.user.current_org_id = defaultOrg.id;
            this.$store.dispatch('fetchData', contextData);
            this.errorLoginMessage = '';
            this.$router.push('/search');
            this.$notify({
              type: 'primary',
              message: `Welcome ${this.$store.state.user.email}`,
              icon: 'tim-icons icon-satisfied',
            });
          } else {
            throw new Error('Cannot fetch context');
          }
        } else if (response.status === 401) {
          const errorResponse = await response.json();
          throw new Error(this.$t("errors.backend."+errorResponse.detail));
        } else if (response.status === 429) {
          throw new Error("Too Many Requests");
        } else {
          throw new Error('Unauthorized resource');
        }
      } catch (error) {
        this.errorLoginMessage = error.message;
      }
    },
  },
};
</script>

<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.card .alert {
  position: relative !important;
  width: 100%;
}

#form-register {
  background-color: white;
  color: black;
  border-radius: 20px;
}

#form-register h4 {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}

#form-register i {
  color: black;
}

#form-register input {
  color: black;
}

.modal-body,
.modal-footer {
  padding: 24px 24px 16px 24px;
}
</style>
