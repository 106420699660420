export const terms_en = `GENERAL TERMS AND CONDITIONS OF OWLYSCAN are available at <a href="https://owlyscan.com/en/legal-information-pricing-and-terms/">https://owlyscan.com/en/legal-information-pricing-and-terms/</a>.
<br/>
<br/>
ACCEPTABLE USE POLICY FOR THE SAAS SERVICE "OWLYSCAN" BY SITINCLOUD<br/>
<br/>
The purpose of this Acceptable Use Policy (AUP) is to specify the authorized and prohibited conditions of use of the OwlyScan SaaS application published by SitinCloud.<br/>
<br/>
Prohibited Uses<br/>
It is prohibited to use OwlyScan to:<br/>
- Commit or attempt to commit any illegal or fraudulent activity<br/>
- Access, collect, or store illegal data and content (counterfeiting, child pornography content, content inciting racial hatred, etc.)<br/>
- Violate the intellectual property rights of third parties<br/>
- Attempt to compromise the security or integrity of OwlyScan (hacking, injection of malicious code, denial of service, etc.)<br/>
- Impersonate another person or entity<br/>
- Resell or make available access to OwlyScan to unauthorized third parties<br/>
- Extract or substantially reuse the content of OwlyScan databases<br/>
<br/>
Any abusive, excessive, or unlawful use of OwlyScan may result in the unilateral and immediate suspension or termination of access to the application, without prejudice to legal proceedings that may be initiated against the User.<br/>
<br/>
Reporting<br/>
If you notice any use of OwlyScan that is contrary to this AUP, please report it immediately to SitinCloud at contact@sitincloud.com.<br/>
`;

export const terms_fr = `Les CONDITIONS GENERALES D'UTILISATION D'OWLYSCAN sont disponibles à cette adresse : <a href="https://www.owlyscan.com/informations-legales-conditions-generales/">https://www.owlyscan.com/informations-legales-conditions-generales/</a>
<br/>
<br/>
POLITIQUE D'UTILISATION ACCEPTABLE DU SERVICE SAAS « OWLYSCAN » DE SITINCLOUD<br/>
<br/>
La présente Politique d'Utilisation Acceptable (PUA) a pour objet de préciser les conditions d'utilisation autorisées et interdites de l'application SaaS OwlyScan éditée par SitinCloud.<br/>
<br/>
Utilisations interdites<br/>
Il est interdit d'utiliser OwlyScan pour :<br/>
<br/>
Commettre ou tenter de commettre toute activité illégale ou frauduleuse<br/>
Accéder, collecter ou stocker des données et contenus illicites (contrefaçon, contenu pédopornographique, contenu incitant à la haine raciale, etc.)<br/>
Violer les droits de propriété intellectuelle de tiers<br/>
Tenter de compromettre la sécurité ou l'intégrité d'OwlyScan (piratage, injection de code malveillant, déni de service, etc.)<br/>
Usurper l'identité d'une autre personne ou entité<br/>
Revendre ou mettre à disposition de tiers non autorisés l'accès à OwlyScan<br/>
Extraire ou réutiliser de façon substantielle le contenu des bases de données d'OwlyScan<br/>
Tout usage abusif, excessif ou illicite d'OwlyScan pourra entraîner la suspension ou la résiliation unilatérale et immédiate de l'accès à l'application, sans préjudice des poursuites judiciaires qui pourraient être engagées à l'encontre de l'Utilisateur.<br/>
<br/>
Signalement<br/>
Si vous constatez une utilisation d'OwlyScan contraire à la présente PUA, merci de le signaler immédiatement à SitinCloud à l'adresse contact@sitincloud.com.<br/>
`;
