<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto text-center">
          <h1 class="title"> {{ $t('buy.creditCard.title') }}</h1>
          <h4 class="description">
             {{ $t('buy.creditCard.subtitle') }}
          </h4>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex flex-row flex-wrap justify-content-center">
          <div v-for="(pricingCard, index) in pricingCards" :key="index" class="m-4">
            <pricing-payment-card :title="pricingCard.title" :features="pricingCard.features" :price="pricingCard.price"
                                  :price_detail="pricingCard.price_detail"
                                  :price_id="pricingCard.price_id"
                                  :image-src="pricingCard.imageSrc"
                                  :footers="pricingCard.footers"
                                  :is-featured="pricingCard.isFeatured"></pricing-payment-card>
          </div>
        </div>
      </div>
    </div>

    <div class="container">


    </div>
  </div>
</template>
<script>
// import {BaseProgress} from 'src/components';
import PricingPaymentCard from "@/pages/Pages/PricingPaymentCard.vue";

export default {
  data() {
    return {
      "pricingCards": [
        {
          "title": "1",
          "imageSrc": "card-success.png",
          "features": {
            "en": ["1 Query"],
            "fr": ["1 Recherche"]
          },
          "price": 150,
          "price_detail": {
            "en": "Incl. taxes",
            "fr": "TTC"
          },
          "footers": {
            "en": ["By credit card", "Usable within 12 months"],
            "fr": ["Par CB", "Utilisable dans les 12 mois"]
          },
          "isFeatured": false,
          "price_id": "query-1"
        },
        {
          "title": "5",
          "imageSrc": "card-warning.png",
          "features": {
            "en": ["5 Queries"],
            "fr": ["5 Recherches"]
          },
          "price": 600,
          "price_detail": {
            "en": "Incl. taxes",
            "fr": "TTC"
          },
          "footers": {
            "en": ["120 € / Query", "By credit card", "Usable within 12 months"],
            "fr": ["120 € / Recherche", "Par CB", "Utilisable dans les 12 mois"]
          },
          "isFeatured": false,
          "price_id": "query-5"
        },
        {
          "title": "10",
          "imageSrc": "card-warning.png",
          "features": {
            "en": ["10 Queries"],
            "fr": ["10 Recherches"]
          },
          "price": 1000,
          "price_detail": {
            "en": "Incl. taxes",
            "fr": "TTC"
          },
          "footers": {
            "en": ["100 € / Query", "By credit card", "Usable within 12 months"],
            "fr": ["100 € / Recherche", "Par CB", "Utilisable dans les 12 mois"]
          },
          "isFeatured": false,
          "price_id": "query-10"
        }
      ]
    }
  },
  components: {
    // BaseProgress,
    PricingPaymentCard,
  }
};
</script>
<style>

.pricing-card {
  flex-basis: 2000px;
  color: red;
}

</style>
